import React, { useEffect, useState } from 'react';
import { getLocationById } from '../../../services/ShipmentTrackerService';
import { AddInspectionEntity, buildInspectLink, ScvEntityType } from './ScvCommanderEntityUtils';

/**
 * SCV Commander component to take a location ID and render it as the location name
 */
interface LocationNameProps {
  locationId: string;
  addInspectEntityFunc: AddInspectionEntity;
  showLinks: boolean;
}

export function LocationName(props: LocationNameProps) {
  const [name, setName] = useState<string>('-');

  useEffect(() => {
    if (props.locationId) {
      getLocationById(props.locationId).then((location) => setName(location.name));
    }
  }, [props.locationId]);

  if (props.showLinks) {
    const inspectLink = buildInspectLink(ScvEntityType.location, props.locationId, props.addInspectEntityFunc, false);
    return (
      <>
        {name} {inspectLink}
      </>
    );
  }
  return <>{name}</>;
}
