import React, { useEffect, useState } from 'react';
import { Card, Collapse, Table } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons';
import { Cargo, CargoEvent, Transport, TransportEvent } from '@beacon-devops/shipment-tracker-client';
// Local imports
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { getEventsByCargo, getTransportsByCargo } from '../../../services/ShipmentTrackerService';
import { TitleBar, TitleContainer, TitleWithoutMargin } from './styles';
import {
  ScvEntityType,
  AddInspectionEntity,
  buildInspectLink,
  renderEntityItem,
  renderEntityItemDateTime,
} from './ScvCommanderEntityUtils';
import { LocationName } from './LocationName';
import { TransportCallName } from './TransportCallName';

interface ScvCommanderEntityCargoProps {
  entityId: string;
  entityData: Cargo;
  addInspectionEntityFunc: AddInspectionEntity;
  backgroundColor: string;
}

/**
 * This component renders a Cargo given some data from Shipment Tracker. It will also query shipment tracker for child elements like
 * transports & events which will also be rendered.
 */
export const ScvCommanderEntityCargo = (props: ScvCommanderEntityCargoProps): JSX.Element => {
  const cargo: Cargo = props.entityData;
  const [cargoEvents, setCargoEvents] = useState<CargoEvent[]>();
  const [transportEvents, setTransportEvents] = useState<TransportEvent[]>();
  const [transports, setTransports] = useState<Transport[]>();

  useEffect(() => {
    if (cargo.id) {
      const cargoId = cargo.id;
      getEventsByCargo(cargoId)
        .then((data) => {
          if (data.cargoEvents) {
            data.cargoEvents.sort((a, b) => {
              const aTimestamp = a.eventDateTime.timestamp ?? '';
              const bTimestamp = b.eventDateTime.timestamp ?? '';
              return aTimestamp.localeCompare(bTimestamp);
            });
            setCargoEvents(data.cargoEvents);
          }
          if (data.transportEvents) {
            data.transportEvents.sort((a, b) => {
              const aTimestamp = a.eventDateTime.timestamp ?? '';
              const bTimestamp = b.eventDateTime.timestamp ?? '';
              return aTimestamp.localeCompare(bTimestamp);
            });
            setTransportEvents(data.transportEvents);
          }
        })
        .catch((error) => {
          console.error('Error fetching cargo events:', error);
          setCargoEvents([]);
        });
      getTransportsByCargo(cargoId)
        .then((data) => {
          data.sort((a, b) => (a.carrierShipmentSequenceNo ?? 0) - (b.carrierShipmentSequenceNo ?? 0));
          setTransports(data);
        })
        .catch((error) => {
          console.error('Error fetching transports:', error);
          setTransports([]);
        });
    }
  }, [cargo.id]);

  // Table column definitions
  const eventTableColumns = [
    {
      title: 'Type',
      dataIndex: 'subType',
    },
    {
      title: 'Classifier',
      dataIndex: 'classifier',
    },
    {
      title: 'Source',
      dataIndex: ['source', 'identifier'],
    },
    {
      title: 'Timestamp',
      dataIndex: ['eventDateTime', 'timestamp'],
    },
    {
      title: 'Location',
      dataIndex: 'locationId',
      render: (locationId: string) => (
        <LocationName locationId={locationId} addInspectEntityFunc={props.addInspectionEntityFunc} showLinks />
      ),
    },
    {
      title: <ZoomInOutlined />,
      dataIndex: 'id',
      render: (eventId: string) =>
        eventId.match('cargo')
          ? buildInspectLink(ScvEntityType.cargoEvent, eventId, props.addInspectionEntityFunc)
          : buildInspectLink(ScvEntityType.transportEvent, eventId, props.addInspectionEntityFunc),
    },
  ];
  const transportTableColumns = [
    {
      title: 'Sequence',
      dataIndex: 'sequenceNo',
    },
    {
      title: 'Mode',
      dataIndex: 'mode',
    },
    {
      title: 'Load',
      dataIndex: 'loadTransportCallId',
      render: (transportCallId: string) => (
        <TransportCallName transportCallId={transportCallId} addInspectEntityFunc={props.addInspectionEntityFunc} />
      ),
    },
    {
      title: 'Discharge',
      dataIndex: 'dischargeTransportCallId',
      render: (transportCallId: string) => (
        <TransportCallName transportCallId={transportCallId} addInspectEntityFunc={props.addInspectionEntityFunc} />
      ),
    },
    {
      title: <ZoomInOutlined />,
      dataIndex: 'id',
      render: (transportId: string) =>
        buildInspectLink(ScvEntityType.transport, transportId, props.addInspectionEntityFunc),
    },
  ];

  function renderCargoEvents() {
    return <Table dataSource={cargoEvents} columns={eventTableColumns} rowKey="id" />;
  }

  function renderTransportEvents() {
    return <Table dataSource={transportEvents} columns={eventTableColumns} rowKey="id" />;
  }

  function renderTransports() {
    return <Table dataSource={transports} columns={transportTableColumns} rowKey="id" />;
  }

  /**
   * Renders the cargo card plus any extra information for this entity
   * @param cargo Cargo entity from Shipment Tracker
   */
  function render(cargo: Cargo): JSX.Element {
    return (
      <div>
        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>
              Cargo: {cargo?.container ? cargo.container.containerNumber : cargo?.id}
            </TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Card>
          {renderEntityItemDateTime('Created At', cargo.createdAt)}
          {renderEntityItemDateTime('Last Updated At', cargo.updatedAt)}
          {renderEntityItemDateTime('Stopped Tracking At', cargo.stoppedTrackingAt)}
          {renderEntityItem(
            'CarrierShipment',
            buildInspectLink(
              ScvEntityType.carrierShipment,
              cargo.carrierShipmentId,
              props.addInspectionEntityFunc,
              true,
            ),
          )}
          {renderEntityItem('Status', cargo.status)}
          {renderEntityItem('Number of Packages', cargo.numberOfPackages)}
          {renderEntityItem('Container Number', cargo.container?.containerNumber)}
          {renderEntityItem('Container Type', cargo.container?.isoCode)}
        </Card>

        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>Transports</TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Collapse>
          <CollapsePanel header="Transports" key="transports" style={{ background: props.backgroundColor }}>
            {renderTransports()}
          </CollapsePanel>
        </Collapse>

        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>Cargo Events</TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Collapse>
          <CollapsePanel header="Cargo Events" key="cargoEvents" style={{ background: props.backgroundColor }}>
            {renderCargoEvents()}
          </CollapsePanel>
        </Collapse>

        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>Transport Events</TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Collapse>
          <CollapsePanel header="Transport Events" key="transportEvents" style={{ background: props.backgroundColor }}>
            {renderTransportEvents()}
          </CollapsePanel>
        </Collapse>
      </div>
    );
  }

  return <div>{cargo ? render(cargo) : '...loading'}</div>;
};
