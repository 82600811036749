import React, { useEffect, useState } from 'react';
import { getTransportCallById } from '../../../services/ShipmentTrackerService';
import { LocationName } from './LocationName';
import { AddInspectionEntity, buildInspectLink, ScvEntityType } from './ScvCommanderEntityUtils';

/**
 * SCV Commander component to take a transport call ID and render it as the location name plus a link
 */
interface TransportCallNameProps {
  transportCallId: string;
  addInspectEntityFunc: AddInspectionEntity;
}

export function TransportCallName(props: TransportCallNameProps) {
  const [locationId, setLocationId] = useState<string>('-');

  useEffect(() => {
    if (props.transportCallId) {
      getTransportCallById(props.transportCallId).then((transportCall) =>
        setLocationId(transportCall.locationId ?? ''),
      );
    }
  }, [props.transportCallId]);

  const inspectLink = buildInspectLink(
    ScvEntityType.transportCall,
    props.transportCallId,
    props.addInspectEntityFunc,
    false,
  );

  return (
    <>
      <LocationName locationId={locationId} addInspectEntityFunc={props.addInspectEntityFunc} showLinks={false} />{' '}
      {inspectLink}
    </>
  );
}
